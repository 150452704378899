$('a.search').click(function () {
   $('.search-form').addClass('active');
});

$('a.close').click(function () {
    $('.search-form').removeClass('active');
});


$('.mobile-menu-opener').click(function () {
    $('header').toggleClass('menu-active');
});