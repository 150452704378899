$(function () {
    if ($('.homepage').length) {
        $('.main-slider').slick({
            autoplay: true,
            draggable: true,
            fade: false,
            speed: 1000,
            arrows: true,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            slide: '.slide',
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>'
        });

        $('.products-slider').slick({
            autoplay: false,
            draggable: true,
            fade: false,
            speed: 1000,
            arrows: false,
            dots: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            slide: '.product',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });


    }

    $('section.products .arrow.next').click(function () {
        $('.products-slider').slick('slickNext');
    });
    $('section.products .arrow.prev').click(function () {
        $('.products-slider').slick('slickPrev');
    });



    $('.fairs-slider').slick({
        autoplay: false,
        infinite: true,
        draggable: true,
        fade: false,
        speed: 1000,
        arrows: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        slide: '.fair',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('section.fairs .arrow.next').click(function () {
        $('.fairs-slider').slick('slickNext');
    });
    $('section.fairs .arrow.prev').click(function () {
        $('.fairs-slider').slick('slickPrev');
    });
});